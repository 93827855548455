@font-face {
font-family: '__poppins_7fe156';
src: url(/_next/static/media/4b4ebe20759bdbf2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__poppins_7fe156';
src: url(/_next/static/media/a52d0bf095c248da-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__poppins_7fe156';
src: url(/_next/static/media/98512bf8da1afe43-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__poppins_7fe156';
src: url(/_next/static/media/8001a01b32b71ef5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__poppins_Fallback_7fe156';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_7fe156 {font-family: '__poppins_7fe156', '__poppins_Fallback_7fe156'
}.__variable_7fe156 {--font-family-primary: '__poppins_7fe156', '__poppins_Fallback_7fe156'
}

.toast__container .toast__close-btn{background:rgba(0,0,0,0);border:none;outline:none;padding:0;color:#fff;font-size:20px}.toast__container .toast__bar{font-size:20px;padding:10px 12px;max-width:unset}
