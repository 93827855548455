.toast__container {
  .toast__close-btn {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: #fff;
    font-size: 20px;
  }
  .toast__bar {
    font-size: 20px;
    padding: 10px 12px;
    max-width: unset;
  }
}
